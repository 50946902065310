import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types"
// Custom Hooks
import useAuth from "../../hooks/fetch/useAuth"
import usePIN from "../../hooks/usePIN";
import useResendOtp from '../../hooks/useResendOtp';
import useExtractPIN from 'hooks/useExtractPIN';
// Componets
import Button from '../Button/Button';
import Logo from '../../assets/icons/Logo';
import BackButton from '../Button/BackButton';
import Header from '../Header/Header';
import Pin from '../Input/Pin';
import Label from '../Label/Label';
import FormWrapper from '../FormWrapper';

export default function VerifyAccountForm({ description, callback, userStatus, email, backButton }) {
  const { loading, recoverUser, signedUpUser } = useSelector((state) => state.auth);
  const inputRef = useRef([]);
  const { extractPIN } = useExtractPIN()
  const [pin, onPaste, onChange, onPress] = usePIN(6, inputRef)
  const { timeLeft, resetTimer } = useResendOtp()
  const { verify_account, validate_password_reset_Token, resend_verification_email, forgot_password } = useAuth()

  const onSubmit = useCallback(() => {
    userStatus === 'new' ?
      verify_account(pin, callback) :
      validate_password_reset_Token(recoverUser?.email, pin, callback)
  }, [pin, recoverUser?.email])

  const disabled = useMemo(() => {
    return extractPIN(pin)?.length < 6
  }, [pin])

  return (
    <section className="w-full md:px-40 md:py-[32px]">
      <Logo />
      <div className="w-full sm:max-w-[90%] md:mt-[72px] mt-[40px] mx-auto cursor-pointer hide_tap">
        <BackButton onClick={backButton || undefined} />
      </div>
      <FormWrapper
        className={`transition_left max-w-[400px] mx-auto mt-0 sm:mt-[70px]`}
        onSubmit={onSubmit}
      >
        <Header
          title="Verify Account"
          variant="form"
          description={description}
        />
        <Label title='Enter Code' />
        <Pin
          id='code'
          name='code'
          type="text"
          pin={pin}
          inputRef={inputRef}
          onChange={onChange}
          onKeyDown={onPress}
          onPaste={onPaste}
          readOnly={loading}
          className="grid grid-cols-6 gap-4 mt-2"
        />
        <Button
          name={userStatus === 'new' ? 'Verify Code' : 'Next'}
          theme="dark"
          disabled={loading || disabled}
          loading={loading}
          styles="mt-10 w-full"
        />
        <div className='flex items-center justify-center text-center mt-[18px]'>
          <p className='font-dmsans_r text-14 text-dark_blue text-center'>Didn’t get the code? </p>
          <button
            type="button"
            disabled={timeLeft !== 0 || loading}
            className={`py-1 px-3.5 ml-2 rounded-[20px] font-dmsans_b text-12 
            ${timeLeft !== 0 ? "text-dark_blue cursor-not-allowed" : "text-purple bg-purple_light cursor-pointer"}`}
            onClick={() => userStatus === 'new' ? resend_verification_email(signedUpUser?.email || email, resetTimer) : forgot_password({ email: recoverUser?.email }, resetTimer)}
          >
            Resend {timeLeft === 0 ? '' : <span>in {timeLeft}s</span>}
          </button>
        </div>
      </FormWrapper>
    </section>
  );
}

VerifyAccountForm.propTypes = {
  callback: PropTypes.func,
  backButton: PropTypes.func,
  description: PropTypes.string,
  userStatus: PropTypes.string,
  email: PropTypes.string
}