import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Logo from 'assets/icons/Logo';
import LogoBg from 'assets/images/pouchers-page-bg.svg';
import CreateTagForm from 'components/Forms/CreateTagForm';
import CreateTransactionPinForm from 'components/Forms/CreateTransactionPinForm';
import Slider from 'components/Onboarding/Slider';
import EnterDigitCode from 'components/Forms/Settings/TwoFactorAuthentication/EnterDigitCode';
import useUser from 'hooks/fetch/useUser';
import { useNavigate } from 'react-router-dom';

const AuthValidationLayout = () => {
  const navigate = useNavigate();
  const { loggedUser } = useSelector((state) => state.auth);
  const { fetch_profile } = useUser();


  const callback = async () => {
    const res = await fetch_profile();
    if (res?.code === 200) {
      navigate("/")
    }
  };
  const switchContent = () => {
    if (loggedUser?.tag === null) {
      return <CreateTagForm callback={callback} />;
    } else if (loggedUser?.iscreatedpin === false) {
      return <CreateTransactionPinForm callback={callback} />;
    }
  };

  return (
    <div className="fixed inset-0 bg-[#00000030] z-[30] bg-white">
      <img alt="Pouchers" src={LogoBg} className="absolute -bottom-10 right-0" />

      <div className="flex p-[16px] sm:p-6 relative overflow-y-auto min-h-screen">
        <Slider />
        <section className="w-full lg:my-right-layout lg:absolute pt-10 lg:right-4 lg:px-64 xl:pl-112">
          <div className="block lg:hidden">
            <Logo />
          </div>
          <div className="overflow-y-auto overflow-x-hidden">
            <div className="mx-auto max-w-[700px] pt-20 lg:pt-0 lg:mx-0 transition_left flex flex-col items-center justify-center h-full">
              {switchContent()}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AuthValidationLayout;
