import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
// hooks
import useValidateFormData from "hooks/useValidateFormData"
// Icons
import GuestButtonIcon from "../../assets/icons/GuestButtonIcon"
import Header from "../Header/Header"
// Components
import Button from "../Button/Button"
import Input from "../Input/Input"
import FormWrapper from "../FormWrapper"

export default function CreateAccountForm({ formData, onChange, loading, onSubmit }) {
    const { validateFormData } = useValidateFormData()
    const disabled = useMemo(() => {
        let result = []
        Object.keys(formData).forEach((key) => {
            result.push(!validateFormData({ [key]: formData[key] }))
        })
        return result.includes(true)
    }, [formData])

    return (
        <>
            <FormWrapper className="mx-auto max-w-[400px] lg:mx-0 pt-70 sm:pt-28 transition_left" onSubmit={onSubmit}>
                <Header
                    title="Create an Account"
                    variant="form"
                    description="Start managing your expenses with pouchers."
                />
                <div className="mb-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Input
                                label="First Name"
                                type="text"
                                placeholder="Enter name"
                                id="first_name"
                                name="first_name"
                                readOnly={loading}
                                value={formData?.first_name}
                                onChange={onChange}
                            />
                        </div>
                        <div>
                            <Input
                                label="Last Name"
                                type="text"
                                placeholder="Enter name"
                                id="last_name"
                                name="last_name"
                                readOnly={loading}
                                value={formData.last_name}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <p className="text-14 font-dmsans_r text-grey">Your full name should be as they are on your legal documents and BVN.</p>
                </div>
                <div className="mb-4">
                    <Input
                        label="Email Address"
                        type="email"
                        placeholder="Email address"
                        id="email"
                        name="email"
                        readOnly={loading}
                        value={formData.email}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <Input
                        label="Phone Number"
                        type="text"
                        placeholder="Enter phone number"
                        id="phone_number"
                        name="phone_number"
                        inputMode="numeric"
                        readOnly={loading}
                        value={formData.phone_number}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <Input
                        label="Referral Code (optional)"
                        type="text"
                        placeholder="Referral Code"
                        id="referral_code"
                        name="referral_code"
                        readOnly={loading}
                        value={formData.referral_code}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-8">
                    <Input
                        label="Password"
                        type="password"
                        placeholder="Enter password"
                        id="password"
                        name="password"
                        readOnly={loading}
                        value={formData.password}
                        onChange={onChange}
                    />
                </div>
                <Button
                    name="Create Account"
                    theme="dark"
                    disabled={disabled || loading}
                    loading={loading}
                    styles="my-4 w-full"
                />
                <div className="text-14 text-dark_blue font-dmsans_r font-normal mb-[1.5rem]">
                    <p className="text-center mb-10">
                        Already have an account? <Link to="/"><span className="text-purple font-dmsans_b hide_tap">Login</span></Link>
                    </p>
                    <p className="text-center max-w-[335px] leading-24 mx-auto">
                        By clicking create an account, you accept our
                        <a target="_blank" rel="noreferrer" href="https://pouchers.io/terms-of-use" className="hide_tap"> <span className="text-blue underline font-dmsans_b">Terms of Use</span> </a>
                        and <a target="_blank" rel="noreferrer" href="https://pouchers.io/privacy-policy" className="hide_tap"><span className="text-blue underline font-dmsans_b">Privacy Policy.</span></a>
                    </p>
                </div>
            </FormWrapper>
            {/* HIDE GUEST TILL PAYMENT PROCESS FOR GUEST IS RESOLVED switch from sm:hidden to sm:block */}
            <div className="hidden sm:hidden absolute top-8 right-[1rem]">
                <Link to="/guests" className="hide_tap">
                    <div className="flex items-center bg-purple_light p-16 rounded-lg font-dmsans_r font-normal text-14 text-purple">
                        <p>Continue as Guest</p>
                        <GuestButtonIcon />
                    </div>
                </Link>
            </div>
        </>
    )
}

CreateAccountForm.propTypes = {
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    formData: PropTypes.object
}