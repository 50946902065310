/* eslint-disable no-use-before-define */
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useMultiStepForm from "hooks/useMultiStepForm";
import useAuth from "hooks/fetch/useAuth";
import AuthLayout from "components/Layouts/AuthLayout";
import CreateAccountForm from "components/Forms/CreateAccountForm";
import CreateTagForm from "components/Forms/CreateTagForm";
import VerifyAccountForm from "components/Forms/VerifyAccountForm";
import CreateTransactionPinForm from "components/Forms/CreateTransactionPinForm";
import { PHONE_NUMBER_LENGTH } from "constants/index.js";
import { Helmet } from "react-helmet-async";

export default function CreateAccount() {
    let navigate = useNavigate()
    const { signup, loading } = useAuth()
    const { signedUpUser } = useSelector(state => state.auth)
    const location = useLocation();

    // Extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get("ref");
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        referral_code: ref ?? '',
        password: ''
    })

    const nextStep = useCallback(() => {
        next()
    }, [])

    const completed = useCallback(() => {
        navigate('/')
    }, [])

    const signupProxy = useCallback(() => {
        signup(formData, () => nextStep())
    }, [formData])

    const handleChange = useCallback((e) => {
        if (e.target.name === 'phone_number') {
            if (isNaN(e.target.value)) return
            if (formData.phone_number.length === PHONE_NUMBER_LENGTH && e.nativeEvent.inputType !== 'deleteContentBackward') return
        }
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() })
    }, [formData])

    const moveBack = useCallback(() => {
        back()
    }, [])

    const { step, next, back } = useMultiStepForm([
        <CreateAccountForm
            key={0}
            callback={nextStep}
            formData={formData}
            onChange={handleChange}
            loading={loading}
            onSubmit={signupProxy}
        />,
        <VerifyAccountForm
            key={1}
            callback={nextStep}
            backButton={moveBack}
            email={formData?.email}
            userStatus="new"
            description={`Please check your email, a code to verify your account was sent to ${signedUpUser?.email || formData.email}.`}
        />,
        <CreateTagForm
            key={2}
            callback={nextStep}
        />,
        <div key={3} className="pt-70 sm:pt-128">
            <CreateTransactionPinForm
                callback={completed}
            />
        </div>
    ])

    return (
        <>
            <Helmet>
                <title>Create Account - POUCHERS</title>
            </Helmet>
            <AuthLayout
                left={step.key !== "1"}
                center={step.key === "1" ? step : null}
                right={step.key !== "1" ? step : null}
            />
        </>
    )
}