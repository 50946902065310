import { useState } from "react"
import {
    PASSWORD_LENGTH, BVN_LENGTH, PIN_LENGTH, REGEX_EMAIL, REGEX_ACCOUNT_NUMBER,
    REGEX_PHONE, REGEX_PASSWORD, REGEX_FULLNAME, REGEX_LETTER, REGEX_NAME
} from "constants/index.js"

export default function useInputValidate() {
    const [error, setError] = useState('')

    const checkPassword = ({ value }) => {
        if (value.length < PASSWORD_LENGTH || !REGEX_PASSWORD.test(value)) {
            setError(`Password must contain, atleast one uppercase letter, at least one lower case letter,
            at least one digit, at least one special character and must be at least ${PASSWORD_LENGTH} characters length long.`)
            return false
        }

        setError('')
        return true
    }


    const checkEmail = ({ value }) => {
        if (!REGEX_EMAIL.test(value)) {
            setError('Please enter a valid email')
            return false
        }

        setError('')
        return true
    }

    const checkName = ({ value }) => {
        if (!REGEX_NAME.test(value)) {
            if (value?.length < 3) {
                setError("Name must be more than 2 characters")
                return false
            }
            setError("Name must contain only alphabets and cannot have spaces")
            return false
        }
        setError('')
        return true
    }

    const checkFullname = ({ value }) => {
        if (value?.length < 2) {
            setError("Name must be more than 1 character")
            return false
        }
        if (!REGEX_FULLNAME.test(value)) {
            setError("Name must contain only alphabets")
            return false
        }
        setError('')
        return true
    }

    const checkTag = ({ value }) => {
        if (value.length < 3) {
            setError("Tag must be more than 2 characters")
            return false
        }

        if (!REGEX_LETTER.test(value)) {
            setError("Tag must contain only alphabets and no spaces")
            return false
        }

        setError('')
        return true
    }

    const checkPhone = ({ value }) => {
        if (!REGEX_PHONE.test(value)) {
            setError('Please enter a valid phone number')
            return false
        }

        setError('')
        return true
    }

    const checkAccountNumber = ({ value }) => {
        if (!REGEX_ACCOUNT_NUMBER.test(value)) {
            setError('Please enter a valid account number')
            return false
        }

        setError('')
        return true
    }

    const checkEmailOrPhone = ({ value }) => {
        if (!REGEX_PHONE.test(value) && !REGEX_EMAIL.test(value)) {
            setError('Log in with either valid email address or valid phone number')
            return false
        }

        setError('')
        return true
    }

    const checkConfirmPassword = ({ name, value }) => {
        if (value.length === 0) return false
        if (document.getElementById(name)?.value === document.getElementById('password')?.value) {
            setError('')
            return true
        }

        setError('Passwords do not match!')
        return false
    }

    const checkPin = (value) => {
        if (value.length !== PIN_LENGTH) {
            return false
        }

        setError('')
        return true
    }

    const checkEmpty = (value) => {
        if (!value) {
            return false
        }

        setError('')
        return true
    }

    const checkBvn = (value) => {
        if (value.length !== BVN_LENGTH) {
            setError('BVN should be 11 characters')
            return false
        }

        setError('')
        return true
    }

    const validate = ({ name, value }) => {
        switch (name) {
            case 'password':
                return checkPassword({ value })
            case 'first_name':
            case 'last_name':
                return checkName({ value })
            case 'fullname':
                return checkFullname({ value })
            case 'email_address':
            case 'email':
                return checkEmail({ value })
            case 'phone_number':
                return checkPhone({ value })
            case 'account_number':
                return checkAccountNumber({ value })
            case 'email_or_phone':
                return checkEmailOrPhone({ value })
            case 'confirm_password':
                return checkConfirmPassword({ name, value })
            case 'tag':
                return checkTag({ value })
            case 'pin':
                return checkPin(value)
            case 'bvn':
                return checkBvn(value)
            case 'question':
            case 'answer':
            case 'note':
            case 'profile_picture':
                return checkEmpty(value)
            default:
                setError('')
                return true
        }
    }

    return {
        error,
        setError,
        validate,
        checkPin
    }
}