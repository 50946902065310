import React, { useCallback, useMemo, useRef } from "react"
import PropTypes from "prop-types"
// Custom Hooks
import usePIN from "../../../../hooks/usePIN"
import useUser from "../../../../hooks/fetch/useUser"
import useExtractPIN from "hooks/useExtractPIN"
// Components
import Header from '../../../Header/Header'
import Button from "../../../Button/Button"
import Pin from "../../../Input/Pin"
import FormWrapper from "../../../FormWrapper"

export default function EnterDigitCode({ callback }) {
    const inputRef = useRef([]);
    const { validate_2fa, loading } = useUser()
    const { extractPIN } = useExtractPIN()
    const [pin, onPaste, onChange, onPress] = usePIN(6, inputRef)

    const onSubmit = useCallback(() => {
        validate_2fa(pin, callback)
    },[pin])

    const disabled = useMemo(() => {
        return extractPIN(pin)?.length < 6
    },[pin])

    return(
        <FormWrapper onSubmit={onSubmit}>
            <Header
                title="6-digit code"
                variant="form"
            />
            <Pin
                id='code'
                name='code'
                type="text"
                pin={pin}
                inputRef={inputRef}
                onChange={onChange}
                onKeyDown={onPress}
                onPaste={onPaste}
                className="grid grid-cols-6 gap-4 mb-[20px]"
            />
            <p className="text-grey text-14 text-center leading-[20px] mb-[36px]">
            Enter the 6-digit code generated for “Pouchers” from your authenticator. 
            </p>
            <Button 
                name='Confirm'
                theme="dark"
                disabled={disabled || loading}
                loading={loading}
                styles="my-4 w-full"
            />
        </FormWrapper>
    )
}

EnterDigitCode.propTypes = {
    callback: PropTypes.func
}